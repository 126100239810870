










import { Component, Vue, Prop } from "vue-property-decorator";
import VueApexCharts from "vue-apexcharts";
import { DashTipoDatoFecha } from "@/shared/dtos/DashboardsDtos/DashTipoDatoFecha";
import { UtilsDash } from "@/utils/utils-dash";
import { ApexCharsSeries } from "@/shared/dtos/DashboardsDtos/ApexCharsSeries";
import { ThemeConstants } from "@/shared/ThemeConstants";
@Component({
  components: { VueApexCharts }
})
export default class LineMouths extends Vue {
  @Prop({ default: [] }) series!: DashTipoDatoFecha[];
  @Prop({ default: "" }) title!: string;

  public created() {}
  public get categories() {
    return UtilsDash.GetCategories(this.series);
  }
  public get source() {
    let apex: ApexCharsSeries[] = [];
    apex.push(UtilsDash.GetSeries(this.series));
    return apex;
  }
  public get chartOptions() {
    return {
      chart: {
        toolbar: { show: false },
        dropShadow: {
          enabled: true,
          top: 5,
          left: 0,
          blur: 4,
          opacity: 0.1
        }
      },
      stroke: {
        curve: "smooth",
        dashArray: [0, 8],
        width: [4, 2]
      },
      grid: {
        borderColor: "#e7e7e7"
      },
      legend: {
        show: true
      },
      colors: [ThemeConstants.LIGHTER_COLOR],
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          inverseColors: false,
          gradientToColors: [ThemeConstants.DARKER_COLOR],
          shadeIntensity: 1,
          type: "horizontal",
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100, 100, 100]
        }
      },
      markers: {
        size: 0,
        hover: {
          size: 5
        }
      },
      xaxis: {
        labels: {
          style: {
            cssClass: "text-grey fill-current"
          }
        },
        axisTicks: {
          show: false
        },
        categories: this.categories,
        axisBorder: {
          show: false
        }
      },
      yaxis: {
        labels: {
          style: {
            cssClass: "text-grey fill-current"
          },
          formatter: function(val: any) {
            return val > 999 ? (val / 1000).toFixed(1) + "k" : val.toFixed(2);
          }
        }
      },
      tooltip: {
        x: { show: false }
      }
    };
  }
}
